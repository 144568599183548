import React from "react";

const ShowMessage = (props) => {
  return (
    <>
      {props.isMessagePopUp && (
        <div className="loader-popup">
          <div className="popup-text">
            <span>
              <i className="far fa-check-circle"></i>
            </span>
            <h2>Success</h2>
            <p>{props.showMessage}</p>
          </div>
        </div>
      )}

      {props.isMessagePopUpError && (
        <div className="loader-popup">
          <div className="popup-text">
            <span>
              <i className="far fa-times-circle text-danger"></i>
            </span>
            <h5>{props.showMessage || props.errorMessage}</h5>
          </div>
        </div>
      )}
    </>
  );
};
export default ShowMessage;
