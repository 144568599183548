import React, { Component } from "react";
import { homepage, header } from "../config";
import Footer from "./footer";
import Header from "./header";
import axios from "axios";
class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryList: "",
      isCatgoryAddVisible: false,
      category_name: "",
      isMessagePopUp: false,
      isBtnDisable: false,
      isMessagePopUpError: false,
      isApiPopUp: false,
      showMessage: "",
      category_id: "",
      isCategoryEdit: false,
    };
  }
  componentDidMount() {
    this.getCategoryListData();
  }

  getCategoryListData = () => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      token = token.substring(1, token.length - 1);

      axios
        .get(`${homepage.getCategory}?c=${today}`, {
          headers: header,
        })
        .then((response) => {
          this.setState({ categoryList: response.data.data });
        })
        .catch((error) => {
          console.log(error);

          if ((error.response != undefined && error.response.status) == 401) {
            this.setState({ redirect: true });
          }
        });
    }
  };

  handleAddCategory = () => {
    this.setState({ isCatgoryAddVisible: true });
  };

  handleChangeCategory = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  resetForm = () => {
    this.setState({
      category_name: "",
      isCatgoryAddVisible: false,
      isCategoryEdit: false,
      isBtnDisable: false,
    });
  };

  handleSaveCategory = () => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      this.setState({
        isBtnDisable: true,
        isApiPopUp: true,
      });

      token = token.substring(1, token.length - 1);
      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
        "x-access-token": token,
      };
      if (this.state.isCategoryEdit) {
        axios
          .put(
            homepage.updateCategory +
              "/" +
              `${this.state.category_id}?c=${today}`,
            { name: this.state.category_name },
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.setState({
              isCatgoryAddVisible: false,
              isCategoryEdit: false,
            });
            this.resetForm();
            this.getCategoryListData();
            this.setState({
              isMessagePopUp: true,
              showMessage: response.data.message,
              isBtnDisable: false,
              isApiPopUp: false,
              isCategoryEdit: false,
            });
            setTimeout(
              () =>
                this.setState({
                  showMessage: "",
                  isMessagePopUp: false,
                }),
              2000
            );
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Category not add, face server issue",
              isCategoryEdit: false,
              isCatgoryAddVisible: false,
              isApiPopUp: false,
              isBtnDisable: false,
            });
            setTimeout(
              () =>
                this.setState({
                  showMessage: "",
                  isMessagePopUpError: false,
                }),
              2000
            );
          });
      } else {
        axios
          .post(
            `${homepage.AddCategory}?c=${today}`,
            { name: this.state.category_name },
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.setState({
              isCatgoryAddVisible: false,
              isCategoryEdit: false,
            });
            this.resetForm();
            this.getCategoryListData();
            this.setState({
              isMessagePopUp: true,
              showMessage: response.data.message,
              isBtnDisable: false,

              isApiPopUp: false,
              isCategoryEdit: false,
            });
            setTimeout(
              () =>
                this.setState({
                  showMessage: "",
                  isMessagePopUp: false,
                }),
              2000
            );
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Category not add, face server issue",
              isCategoryEdit: false,
              isApiPopUp: false,
              isBtnDisable: false,
            });
            setTimeout(
              () =>
                this.setState({
                  showMessage: "",
                  isMessagePopUpError: false,
                }),
              2000
            );
          });
      }
    }
  };

  handleShowCourse = () => {
    this.setState({ isAddCourseVisible: true });
  };

  handleUpdateCategory = (category_name, category_id) => {
    this.setState({
      isCatgoryAddVisible: true,
      category_name,
      category_id,
      isCategoryEdit: true,
    });
  };

  render() {
    const {
      categoryList,
      isCatgoryAddVisible,
      category_name,
      isMessagePopUp,
      isBtnDisable,
      isMessagePopUpError,
      showMessage,
      isApiPopUp,
    } = this.state;

    const renderDateTime = (dateTime) => {
      var d = new Date(dateTime);

      var month = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var datetimeIs = `${d.getDate()}  ${
        month[d.getMonth()]
      }  ${d.getFullYear()}`;

      return datetimeIs;
    };
    return (
      <div>
        <Header click={this.handleShowCourse} />
        <div className="main-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 custom-tables">
                <h2>
                  Stage Listing
                  <button
                    className="add-stage"
                    data-toggle="modal"
                    onClick={this.handleAddCategory}
                  >
                    <span>
                      <i className="fas fa-plus"></i>
                    </span>
                    Add Stage
                  </button>
                </h2>
                <div className="table-responsive">
                  {/* <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList &&
                      categoryList.map((item) => (
                        <tr>
                          <td>{item.name}</td>
                          <td>
                            <a
                              onClick={() =>
                                this.handleUpdateCategory(item.name, item._id)
                              }
                              className="viewButton"
                            >
                              <span>
                                <i className="fas fa-pencil-alt"></i>
                              </span>
                              Edit
                            </a>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table> */}

                  <table className="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Created Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryList &&
                        categoryList.map((item) => (
                          <tr key={item._id}>
                            <td>{item.name}</td>
                            <td>{renderDateTime(item.created_at)}</td>
                            <td>
                              <button
                                className="edit-link"
                                onClick={() =>
                                  this.handleUpdateCategory(item.name, item._id)
                                }
                              >
                                <span>
                                  <i className="fas fa-pencil-alt"></i>
                                </span>
                                Edit
                              </button>{" "}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        {/*  */}
        {isMessagePopUp && (
          <div className="loader-popup">
            <div className="popup-text">
              <span>
                <i className="far fa-check-circle"></i>
              </span>
              <h2>Success</h2>
              <p>{showMessage}</p>
            </div>
          </div>
        )}

        {isApiPopUp && (
          <div className="loader-popup">
            <div className="loader">
              <i className="fas fa-spinner"></i>
            </div>
          </div>
        )}
        {isMessagePopUpError && (
          <div className="loader-popup">
            <div className="popup-text">
              <span>
                <i className="far fa-times-circle text-danger"></i>
              </span>
              <h2>Error</h2>
              <p>{showMessage}</p>
            </div>
          </div>
        )}
        {/*  */}

        {isCatgoryAddVisible && (
          <div className="modal" id="addLesson" style={{ display: "block" }}>
            <div className="modal-dialog addPopup modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">
                    {/* {chapter_id ? "Edit" : "Add"}  */}
                    Stage
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.resetForm}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>
                          Stage Name{" "}
                          {/* <span style={error_text}>
                            {!formIsValid && errors.category_name}
                          </span> */}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={category_name}
                          name="category_name"
                          onChange={this.handleChangeCategory}
                        />
                      </div>
                    </div>

                    <div className="col-lg-12 button-group">
                      <button
                        type="button"
                        className="btn btn-loaderButton"
                        onClick={this.handleSaveCategory}
                        disabled={isBtnDisable}
                      >
                        <span className="btn-text">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*  */}
        {/*  */}
        <Footer />
      </div>
    );
  }
}

export default Category;
