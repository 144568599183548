import React, { Component } from "react";

import { homepage, header } from "../config";
import AddCourse from "./AddCourseModal";
import DeleteCourse from "../modal/deletecourse";
import Loader from "../modal/loader";
import ShowMessage from "../modal/showmessage";
import axios from "axios";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleList: "",
      isAddCourseVisible: false,
      name: "",
      image: "",
      bg: "",
      user_name: "",
      redirect: false,
      errorMsg: "",
      myToken: "",
      errors: "",
      formIsValid: true,
      showMessage: "",
      isMessagePopUp: false,
      isCatgoryAddVisible: false,
      isBtnDisable: false,
      isBtnLoaderShow: false,
      isApiPopUp: false,
      categoryValue: "Select",
      category_name: "",
      isCatgoryListVisible: false,
      categoryList: "",
      isMessagePopUpError: false,
      isDeleteCourseSuccess: false,
      courseDeleteId: "",
    };
    this.handleFirstCallApi = this.handleFirstCallApi.bind(this);
  }

  componentWillMount() {
    if (this.props.history.location.state) {
      this.setState({ myToken: this.props.history.location.state });
      header["x-access-token"] = this.props.history.location.state;
    }
  }

  userLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.clear();
    this.setState({ redirect: true });
  };

  handleFirstCallApi() {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      token = token.substring(1, token.length - 1);

      axios
        .get(`${homepage.getCourse}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({ moduleList: res.data.data });
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  }

  componentDidMount() {
    if (localStorage.getItem("accessToken")) {
      var userName = localStorage.getItem("name");
      userName = userName.substring(1, userName.length - 1);
      userName = userName.charAt(0).toUpperCase() + userName.slice(1);
      this.setState({ user_name: userName });
      this.handleFirstCallApi();

      document.body.style.background = "hsla(240, 7%, 81%, 0.15)";
    } else {
      this.setState({ redirect: true });

      localStorage.clear();
    }
  }

  handleCloseCourseAdd = () => {
    this.setState({ isAddCourseVisible: false });
    this.resetForm();
  };

  resetForm = () => {
    this.setState({
      name: "",
      image: "",
      bg: "",
      category_name: "",
    });
  };

  handleShowCourse = () => {
    this.setState({ isAddCourseVisible: true });
  };

  handleViewCourse = (courseid, bg, image) => {
    this.props.history.push("/course", { courseid, bg, image });
  };

  handleCourseDetail = (courseid) => {
    localStorage.setItem("course_id", courseid);
    this.props.history.push("/course-details", { courseid });
  };

  handleDeleteCourseSure = () => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      this.setState({
        isBtnDisable: true,
        isBtnLoaderShow: true,
        isApiPopUp: true,
      });

      token = token.substring(1, token.length - 1);

      axios
        .delete(
          homepage.DeleteCourse +
            "/" +
            `${this.state.courseDeleteId}?c=${today}`,
          {
            headers: header,
          }
        )
        .then((response) => {
          this.setState({ isAddCourseVisible: false });
          this.resetForm();
          this.handleFirstCallApi();

          this.setState({
            isMessagePopUp: true,
            isDeleteCourseSuccess: false,
            showMessage: response.data.message,
            isBtnDisable: false,
            isBtnLoaderShow: false,
            isApiPopUp: false,
          });
          setTimeout(
            () => this.setState({ showMessage: "", isMessagePopUp: false }),
            2000
          );
        })
        .catch((error) => {
          this.setState({
            isMessagePopUpError: true,
            showMessage: "Course not deleted, face server issue",
            isApiPopUp: false,
          });
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
          setTimeout(
            () =>
              this.setState({
                showMessage: "",
                isMessagePopUpError: false,
                isDeleteCourseSuccess: false,
              }),
            2000
          );
        });
    }
  };

  handleCourseDelete = (course_id) => {
    this.setState({ courseDeleteId: course_id, isDeleteCourseSuccess: true });
  };

  handleAddCategory = () => {
    this.setState({ isCatgoryAddVisible: true });
  };
  CloseAddCourse = () => {
    this.setState({ isAddCourseVisible: false });
  };

  render() {
    const {
      moduleList,
      isAddCourseVisible,
      name,
      image,
      bg,
      user_name,
      formIsValid,
      errors,
      redirect,
      isMessagePopUpError,
      showMessage,
      isMessagePopUp,
      isBtnDisable,
      isBtnLoaderShow,
      isApiPopUp,
      isDeleteCourseSuccess,
    } = this.state;
    const error_text = { color: "red" };
    return redirect ? (
      <Redirect to={"/login"} />
    ) : (
      <div>
        <header>
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md">
              <Link className="navbar-brand" to="/">
                <img
                  src="images/logo.png"
                  className="img-fluid nav-logo"
                  alt=""
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#collapsibleNavbar"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="collapsibleNavbar">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/">
                      <span className="nav-icon home-icon"></span>Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <button
                      className="nav-link"
                      data-toggle="modal"
                      onClick={() => this.handleShowCourse()}
                    >
                      <span className="nav-icon course-icon"></span>Add Course
                    </button>
                  </li>

                  <li className="nav-item">
                    <Link className="nav-link" to="/category">
                      <span className="nav-icon course-icon"></span>Stage
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="dropdown user-info">
                <button
                  className="nav-link dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <span>
                    <i className="far fa-user"></i>
                  </span>
                  {user_name}
                </button>
                <div className="dropdown-menu">
                  <Link className="dropdown-item" to="user-profile">
                    <span>
                      <i className="far fa-user"></i>
                    </span>
                    Profile
                  </Link>
                  <button className="dropdown-item" onClick={this.userLogOut}>
                    <span>
                      <i className="fas fa-sign-out-alt"></i>
                    </span>
                    Logout
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <section className="main-wrapper">
          <div className="container-fluid">
            <div className="row">
              {Array.isArray(moduleList) > 0
                ? moduleList.map((item) => (
                    <div
                      className="col-xl-3 col-lg-4 col-md-6 col-sm-6"
                      key={item._id}
                    >
                      <div className="course-wrap">
                        <div className="courseImage">
                          <img src={item.image} className="img-fluid" alt="" />
                          <button
                            onClick={() => this.handleCourseDelete(item._id)}
                            className="viewButton"
                          >
                            <span>
                              <i className="far fa-trash-alt"></i>
                            </span>
                          </button>
                        </div>
                        <div className="courseContent">
                          <h2>{item.name}</h2>
                          <div className="courseAction">
                            <button
                              onClick={() =>
                                this.handleViewCourse(
                                  item._id,
                                  item.bg,
                                  item.image
                                )
                              }
                              className="viewButton"
                            >
                              <span>
                                <i className="fas fa-eye"></i>
                              </span>
                              View
                            </button>
                            <button
                              onClick={() =>
                                this.handleCourseDetail(item._id, item.bg)
                              }
                              className="viewButton"
                            >
                              <span>
                                <i className="fas fa-pencil-alt"></i>
                              </span>
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </section>
        <AddCourse
          isAddCourseVisible={isAddCourseVisible}
          handleClose={this.CloseAddCourse}
          getCourseApi={this.handleFirstCallApi}
        />

        <DeleteCourse
          isDeleteCourseSuccess={isDeleteCourseSuccess}
          deleteCourseClose={() =>
            this.setState({ isDeleteCourseSuccess: false })
          }
          deleteCourse={this.handleDeleteCourseSure}
        />

        <Loader isApiPopUp={isApiPopUp} />

        <ShowMessage
          showMessage={showMessage}
          isMessagePopUp={isMessagePopUp}
          isMessagePopUpError={isMessagePopUpError}
        />
      </div>
    );
  }
}
export default Home;
