import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";
import AddCourse from "./AddCourseModal";
import axios from "axios";
import { homepage, header } from "../config";

var today = Math.round(new Date().getTime() / 1000);
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendervalue: "",
      user_name: "",
      redirect: false,
      isAddCourseVisible: false,
    };
  }
  handleShowCourse = () => {
    this.setState({ isAddCourseVisible: true });
  };
  componentDidMount() {
    if (localStorage.getItem("accessToken")) {
      var userName = localStorage.getItem("name");
      userName = userName.substring(1, userName.length - 1);
      userName = userName.charAt(0).toUpperCase() + userName.slice(1);
      this.setState({ user_name: userName });
    } else {
      this.setState({ redirect: true });
      // this.props.history.push("/login");
    }
  }

  handleFirstCallApi() {
    var token = localStorage.getItem("accessToken");
    if (token) {
      token = token.substring(1, token.length - 1);

      axios
        .get(`${homepage.getCourse}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({ moduleList: res.data.data });
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    }
  }

  userLogOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.clear();
    // this.props.history.push("/login");
    this.setState({ redirect: true });
  };
  CloseAddCourse = () => {
    this.setState({ isAddCourseVisible: false });
  };
  render() {
    const { user_name } = this.state;

    return this.state.redirect ? (
      <Redirect to={"/login"} />
    ) : (
      <header>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-md">
            <Link className="navbar-brand" to="/">
              <img
                src="images/logo.png"
                className="img-fluid nav-logo"
                alt=""
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    <span className="nav-icon home-icon"></span>Home
                  </Link>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-toggle="modal"
                    onClick={this.handleShowCourse}
                  >
                    <span className="nav-icon course-icon"></span>Add Course
                  </button>
                </li>

                <li className="nav-item">
                  <Link className="nav-link" to="/category">
                    <span className="nav-icon course-icon"></span>Stage
                  </Link>
                </li>
              </ul>
            </div>
            <div className="dropdown user-info">
              <button
                className="nav-link dropdown-toggle"
                data-toggle="dropdown"
              >
                <span>
                  <i className="far fa-user"></i>
                </span>
                {user_name}
              </button>
              <div className="dropdown-menu">
                <Link className="dropdown-item" to="user-profile">
                  <span>
                    <i className="far fa-user"></i>
                  </span>
                  Profile
                </Link>
                <button className="dropdown-item" onClick={this.userLogOut}>
                  <span>
                    <i className="fas fa-sign-out-alt"></i>
                  </span>
                  Logout
                </button>
              </div>
            </div>
          </nav>
        </div>
        <AddCourse
          isAddCourseVisible={this.state.isAddCourseVisible}
          handleClose={this.CloseAddCourse}
          getCourseApi={this.handleFirstCallApi}
        />
      </header>
    );
  }
}
export default Header;
