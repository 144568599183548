import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import { lessonpage, header } from "../config";

class CourseLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      courseLesson: "",
      topic_id: "",
      course_id: "",
      bg_image: "",
    };
  }

  componentWillMount() {
    if (this.props.history.location.state) {
      const topic_id = this.props.history.location.state.topic_id;
      const course_id = this.props.history.location.state.course_id;
      const bg_image = this.props.history.location.state.bg_image;
      this.setState({ topic_id, course_id, bg_image });
    }
  }

  componentDidMount() {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      document.body.style.backgroundImage = "url(" + this.state.bg_image + ")";
      document.body.style.backgroundSize = "100%";
      axios
        .get(lessonpage.getLesson + "/" + `${this.state.topic_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({ courseLesson: res.data.data });
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleViewchapter = (lesson_id, lesson_name, course_id, background) => {
    const lsnReaderUrl = "/readerlists";
    const { topic_id } = this.state;
    this.props.history.push("/chapters", {
      background,
      lesson_id,
      lesson_name,
      course_id,
      lsnReaderUrl,
      topic_id,
    });
  };

  handleBackBtn = () => {
    const { background, course_id } = this.state;
    this.props.history.push("/course", { background, courseid: course_id });
  };

  render() {
    const { redirect, courseLesson, course_id } = this.state;

    return redirect ? (
      <Redirect to={"/login"} />
    ) : (
        <div>
          <section className="top-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="left-button">
                    <button
                      onClick={this.handleBackBtn}
                      className="action-button"
                    >
                      <img
                        src="images/back-button.png"
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                    <button className="action-button">
                      <img
                        src="images/minimize-button.png"
                        className="img-fluid"
                        alt="It's web version, so not working"
                      />
                    </button>
                    {/* {teacher_img && (
                    <a
                      href={teacher_resouce_link}
                      className="teacher-button"
                      target="_blank"
                    >
                      <img src={teacher_img} className="img-fluid" alt="" />
                    </a>
                  )} */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="top-wrapper">
            <div className="container">
              <div className="bookList scrollBar">
                <div className="row">
                  {Array.isArray(courseLesson) &&
                    courseLesson.map(
                      (item) =>
                        item.btn_type == "Horizontal" && (
                          <div className={"col-lg-3"}>


                            <div
                              className={
                                item.btn_type === "Horizontal"
                                  ? "col-md-12 text-center"
                                  : "col-md-6 text-center"
                              }
                            >
                              <button
                                type="button"
                                className="book-button"
                                onClick={() =>
                                  this.handleViewchapter(
                                    item._id,
                                    item.name,
                                    course_id,
                                    item.lesson_bg_image
                                  )
                                }
                              >
                                <img
                                  src={item.btn_image && item.btn_image}
                                  className="img-fluid"
                                  alt=""
                                />


                              </button>
                            </div>
                          </div>


                        )
                    )}
                </div>
              </div>
            </div>
          </section>
        </div>
      );
  }
}
export default CourseLists;
