import React from "react";

const PublishCourse = (props) => {
  return (
    <>
      {props.isPublishSuccess && (
        <div className="loader-popup">
          <div className="confirmation-loader">
            <button
              type="button"
              className="close"
              onClick={props.publishModalClose}
            >
              &times;
            </button>
            <span>
              <i className="far fa-check-circle text-success"></i>
            </span>
            <h2>Are you sure?</h2>
            <p>Do you really want to publish this course?</p>
            <div className="button-group">
              <button
                className="btn btn-loaderButton"
                onClick={props.handlePublishSure}
              >
                Yes
              </button>
              <button
                className="btn btn-cancel"
                onClick={props.publishModalClose}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PublishCourse;
