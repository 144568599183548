import React, { Component } from "react";
import axios from "axios";
import { lessonpage, header, configpage } from "../config";
import { Redirect } from "react-router";
import Loader from "../modal/loader";

var today = Math.round(new Date().getTime() / 1000);

class Viewlesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseLesson: "",
      background: "",
      course_id: "",
      redirect: false,
      footer_img: "",
      teacher_img: "",
      teacher_resouce_link: "",
      licence_agreement: "",
      copy_right: "",
      term_condition: "",
      license_agreement_img: "",
      terms_condition_img: "",
      isApiPopUp: false,
    };
  }

  getCourseLesson = (topic_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
     
      this.setState({ isApiPopUp: true });
      axios
        .get(lessonpage.getLesson + "/" + `${topic_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({ courseLesson: res.data.data, isApiPopUp: false });
        })
        .catch((error) => {
          this.setState({ isApiPopUp: false });
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  /*Get Config Information*/
  getConfigData = (course_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
      this.setState({ isApiPopUp: true });
      axios
        .get(configpage.getConfig + "/" + `${course_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({
            footer_img_position: res.data.data.footer_img_position,
            teacher_img: res.data.data.teacher_resouce_img,
            teacher_resouce_link: res.data.data.teacher_resouce_link,
            licence_agreement: res.data.data.license_agreement,
            term_condition: res.data.data.terms_condition,
            copy_right: res.data.data.footer_text,
            license_agreement_img: res.data.data.license_agreement_img,
            terms_condition_img: res.data.data.terms_condition_img,
            isApiPopUp: false,
          });
        })
        .catch((error) => {
          this.setState({ isApiPopUp: false });
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };
  /*End of Config API*/

  componentWillMount() {
    if (this.props.location.state) {
      var bg_image = this.props.location.state.bg_image;
      console.log("Bg Image on Lesson page", bg_image);
      var course_id = this.props.location.state.course_id;
      var topic_id = this.props.location.state.topic_id;
      this.setState({
        topic_id,
        background: bg_image,
        course_id,
      });
    }
  }

  componentDidMount() {
    var token = localStorage.getItem("accessToken");
    if (token) {
      document.body.style.backgroundImage =
        "url(" + this.state.background + ")";
      document.body.style.backgroundSize = "100%";

      this.getCourseLesson(this.state.topic_id);
      this.getConfigData(this.state.course_id);
    } else {
      this.setState({ redirect: true });
    }
  }

  handleViewchapter = (lesson_id, lesson_name, background) => {
    const { topic_id, course_id } = this.state;
    const lsnReaderUrl = "/lessons";
    this.props.history.push("/chapters", {
      background,
      lesson_id,
      lesson_name,
      course_id,
      lsnReaderUrl,
      topic_id,
    });
    // this.props.history.push("/resource", {
    //   background,
    //   lesson_id,
    //   lesson_name,
    // });
  };

  handleBackBtn = () => {
   /* const { background, course_id } = this.state;
    this.props.history.push("/course", {
      background,
      courseid: course_id,
    });*/
    this.props.history.goBack();
  };

  render() {
    const {
      background,
      courseLesson,
      redirect,
      teacher_img,
      teacher_resouce_link,
      course_id,
      licence_agreement,
      term_condition,
      license_agreement_img,
      terms_condition_img,
      copy_right,
      isApiPopUp,
    } = this.state;

    // var divStyle = {
    //   backgroundImage: "url(" + background + ")",
    // };

    return redirect ? (
      <Redirect to={"/login"} />
    ) : (
      <div>
        <section className="top-wrapper" id="topWrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8">
                <div className="left-button">
                  <button
                    className="action-button"
                    onClick={this.handleBackBtn}
                  >
                    <img
                      src="images/back-button.png"
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                  <button className="action-button">
                    <img
                      src="images/minimize-button.png"
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                  {teacher_img && (
                    <a
                      href={teacher_resouce_link}
                      className="teacher-button"
                      target="_blank"
                    >
                      <img src={teacher_img} className="img-fluid" alt="" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="main-wrapper1">
          <div className="container-fluid">
            <div className="bookList scrollBar">
              <div className="row">
                        {Array.isArray(courseLesson) &&
                          courseLesson.map((item) => 
                            (item.btn_type == "Vertical" ||  item.btn_type == "2_colomn") && (
                             
                            <div
                              className={
                                item.btn_type === "Vertical"
                                  ? "col-md-12 text-center " + item.btn_type
                                  : "col-md-6 text-center " + item.btn_type
                              }
                              key={item._id}
                            >
                              <button
                                type="button"
                                className="button-1"
                                style={{
                                  backgroundColor: item.btn_color,
                                  width: item.btn_width + "px",
                                  height: item.btn_height + "px",
                                  backgroundSize: "cover",
                                  color: item.btn_font_color,
                                  fontSize: item.btn_font_size + "px",
                                  // backgroundImage: `url(${background})`,
                                  //   item.btn_image != "" &&
                                  //   item.btn_image != null &&
                                  //   item.btn_image != "null"
                                  //     ? "url(" +
                                  //       item.btn_image +
                                  //       ") no-repeat top center"
                                  //     : "",
                                }}
                                onClick={() =>
                                  this.handleViewchapter(
                                    item._id,
                                    item.name,
                                    item.lesson_bg_image
                                  )
                                }
                              >
                                <span>
                                  <img
                                    src={item.btn_icon && item.btn_icon}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </span>
                                {item.name}
                              </button>
                            </div>
                          ))}

                          {Array.isArray(courseLesson) &&
                          courseLesson.map((item) => 
                         
                            item.btn_type == "Horizontal" && (
                          
                              <div className={"col-lg-3"}>


                                <div
                                  className={
                                    item.btn_type === "Horizontal"
                                      ? "col-md-12 text-center"
                                      : "col-md-6 text-center"
                                  }
                                >
                                  <button
                                    type="button"
                                    className="book-button"
                                    onClick={() =>
                                      this.handleViewchapter(
                                        item._id,
                                        item.name,
                                        item.lesson_bg_image
                                      )
                                    }
                                  >
                                    <img
                                      src={item.btn_image && item.btn_image}
                                      className="img-fluid"
                                      alt=""
                                    />


                                  </button>
                                </div>
                              </div>
                          ))}
                      </div>
            </div>
          </div>
        </section>
        <Loader isApiPopUp={isApiPopUp} />
        
      </div>
    );
  }
}
export default Viewlesson;
