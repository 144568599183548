import React from "react";

const DeleteChapter = (props) => {
  return (
    <>
      {props.isDeleteSuccess && (
        <div className="loader-popup">
          <div className="confirmation-loader">
            <button
              type="button"
              className="close"
              onClick={props.deleteChapterClose}
            >
              &times;
            </button>
            <span>
              <i className="far fa-times-circle text-danger"></i>
            </span>
            <h2>Are you sure?</h2>
            <p>
              Do you really want to delete these records? This process cannot be
              undone.
            </p>
            <div className="button-group">
              <button
                className="btn btn-delete"
                onClick={props.handleDeleteChapterSure}
              >
                Delete
              </button>
              <button
                className="btn btn-cancel"
                onClick={props.deleteChapterClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeleteChapter;
