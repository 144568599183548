import React, { useEffect, useState } from "react";

import Iframe from "react-iframe";

export const IframeType = React.memo(function IframeType(props) {
  const [screenHeight, setScreenHeight] = useState("");
  useEffect(() => {
    var offsetHeight = document.getElementById("topWrapper").offsetHeight + 30;
    setScreenHeight(window.innerHeight - offsetHeight + "px");
    if (props.url) {
      props.loader(false);
    }
  }, [props.url, setScreenHeight]);

  return (
    <Iframe
      url={props.url}
      width="90%"
      height={screenHeight}
      id="chapterId"
      className="myClassname horizon"
      display="initial"
      position="relative"
      frameBorder="0"
    />
  );
});
