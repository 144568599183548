import React, { PureComponent } from "react";
import { chapterpage, header, configpage, homepage } from "../config";
import axios from "axios";
import { Redirect } from "react-router";
import Loader from "../modal/loader";
import $ from "jquery";

import { VideoType } from "../resourcetypes/video";
import { ImageType } from "../resourcetypes/image";
import { IframeType } from "../resourcetypes/iframe";
var today = Math.round(new Date().getTime() / 1000);

class Viewchapter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      background: "",
      redirect: false,
      lesson_id: "",
      chapterContent: "",
      use_template: "",
      template_type: "",
      lesson_name: "",
      id: "",
      course_lession: "",
      chapter_name: "Select chapter",
      count: 0,
      isIframeNextContent: false,
      temp_height: "",
      temp_width: "",
      isTeamScoreCounterShow: false,
      isTeamScoreCounter: false,
      isTeamScore2Show: false,
      isTeamScore3Show: false,
      isTeamScore4Show: false,
      isTeamScore5Show: false,
      isCheckedTeam1: false,
      isCheckedTeam2: false,
      isCheckedTeam3: false,
      isCheckedTeam4: false,
      isCheckedTeam5: false,
      count1: 0,
      count2: 0,
      count3: 0,
      count4: 0,
      count5: 0,
      course_id: "",
      footer_img: "",
      teacher_img: "",
      teacher_resouce_link: "",
      width: "",
      height: "",
      number_picker: "",
      score_counter: "",
      categoryList: "",
      vidWidth: "",
      vidHeight: "",
      isApiPopUp: false,
      lsnReaderUrl: "",
      topic_id: "",
      bg_image: "",
    };
    this.imgRef = React.createRef();
  }

  getCategoryListData = () => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      token = token.substring(1, token.length - 1);

      axios
        .get(`${homepage.getCategory}?c=${today}`, {
          headers: header,
        })
        .then((response) => {
          this.setState({ categoryList: response.data.data });
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    }
  };

  getCoursechapter = (lesson_id) => {
    var token = localStorage.getItem("accessToken");

    if (token) {
      axios
        .get(
          chapterpage.getChapterByLessonId + "/" + `${lesson_id}?c=${today}`,
          {
            headers: header,
          }
        )
        .then((res) => {
          if (res.data) {
            this.setState({
              chapterContent: res.data.data,
              use_template: res.data.data[0].use_template,
              chapter_name: res.data.data[0].name,
              template_type: res.data.data[0].template_type,
              categoryData: res.data.category,
            });
          }
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  componentWillMount() {
    if (this.props.location.state) {
     
      var bg_image = this.props.location.state.background;
      var lesson_id = this.props.location.state.lesson_id;
      var lesson_name = this.props.location.state.lesson_name;
      var course_lession = this.props.location.state.course_lession;
      var course_id = this.props.location.state.course_id;
      var lsnReaderUrl = this.props.location.state.lsnReaderUrl;
      var topic_id = this.props.location.state.topic_id;
      this.setState({
        background: bg_image,
        lesson_id: lesson_id,
        lesson_name: lesson_name,
        course_lession,
        course_id,
        lsnReaderUrl,
        topic_id,
        bg_image,
      });
    }
  }

  componentDidMount() {
    var token = localStorage.getItem("accessToken");
    const { background, course_id } = this.state;
    if (token) {
       document.body.style.backgroundImage = "url(" + background + ")";
      document.body.style.backgroundSize = "100%";
      // this.updateToolWindow();
      this.getCoursechapter(this.state.lesson_id);
      this.getConfigData(course_id);
      this.getCategoryListData();

      window.toolButton();
    } else {
      this.setState({ redirect: true });
    }
  }

  /*Get Config Information*/
  getConfigData = (course_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
      axios
        .get(configpage.getConfig + "/" + `${course_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({
            footer_img_position: res.data.data.footer_img_position,
            teacher_img: res.data.data.teacher_resouce_img,
            teacher_resouce_link: res.data.data.teacher_resouce_link,
            score_counter: res.data.data.score_counter,
            number_picker: res.data.data.number_picker,
          });
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };
  /*End of Config API*/

  handleViewchapter = (use_template, name, id, template_type) => {


    var chapterUnitIndex = this.state.chapterContent.findIndex(
      (item) => item._id == id
    );
    window.playButtonClick();
    this.setState({
      use_template: use_template,
      chapter_name: name,
      id: id,
      isIframeNextContent: false,
      count: chapterUnitIndex,
      template_type,
      isApiPopUp: true,
    });
  };

  handlePreviousChapter = () => {
    const { count } = this.state;
    window.playButtonClick();
    if (0 < count) {
      this.setState({
        count: this.state.count - 1,
        isIframeNextContent: true,
        isApiPopUp: true,
      });
    }
  };

  handleNextChapter = () => {
    const { count, chapterContent } = this.state;
    window.playButtonClick();
    if (count < chapterContent.length - 1) {
      this.setState({
        count: this.state.count + 1,
        isIframeNextContent: true,
        isApiPopUp: true,
      });
    }
  };

  handleBackBtn = () => {
    //const { lsnReaderUrl, topic_id, course_id, bg_image } = this.state;
    //this.props.history.push(lsnReaderUrl, { bg_image, course_id, topic_id });
    this.props.history.goBack();
  };

  loaderFile = (value) => {
    this.setState({ isApiPopUp: value });
  };

  render() {
    const {
      background,
      redirect,
      chapterContent,
      use_template,
      lesson_name,
      chapter_name,
      course_lession,
      count,
      isIframeNextContent,
      teacher_img,
      teacher_resouce_link,
      course_id,
      template_type,
      width,
      height,
      score_counter,
      categoryData,
      categoryList,
      isApiPopUp,
    } = this.state;

    // var divStyle = {
    //   backgroundImage: "url(" + background + ")",
    // };

    var categoryDataList =
      categoryData &&
      Object.keys(categoryData).map((k) => {
        let o = categoryList && categoryList.find((o) => o._id === k);
        if (!o) return { null: categoryData[k] };
        return { [o.name]: categoryData[k] };
      });

    var newCatData = "";
    if (categoryDataList) {
      newCatData = Object.assign({}, ...categoryDataList);
    }

    if (
      this.state.number_picker == "Yes" ||
      this.state.score_counter == "Yes"
    ) {
      $("#toolContainer").css("display", "block");
      if (this.state.number_picker == "Yes") {
        $("#number_picker_tool").css("display", "block");
      }
      if (this.state.score_counter == "Yes") {
        $("#scoreViewContainer").css("display", "block");
      }
    }

    const renderTypeContent = (contentType) => {
      switch (contentType) {
        case "PNG":
        case "JPG":
          return (
            <ImageType
              url={
                isIframeNextContent
                  ? chapterContent[count].use_template
                  : use_template
              }
              loader={this.loaderFile}
            />
          );
          break;

        case "Video":
          return (
            <VideoType
              url={
                isIframeNextContent
                  ? chapterContent[count].use_template
                  : use_template
              }
              title={
                isIframeNextContent ? chapterContent[count].name : chapter_name
              }
              loader={this.loaderFile}
            />
          );
          break;
        default:
          return (
            <IframeType
              url={
                isIframeNextContent
                  ? chapterContent[count].use_template
                  : use_template
              }
              loader={this.loaderFile}
            />
          );
          break;
      }
    };

    return redirect ? (
      <Redirect to={"/login"} />
    ) : (
        <div>
          <section className="top-wrapper" id="topWrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-8 col-lg-7 col-md-6 col-sm-6">
                  <div className="left-button">
                    <button
                      className="action-button"
                      onClick={this.handleBackBtn}
                    >
                      <img
                        src="images/back-button.png"
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                    <button className="action-button">
                      <img
                        src="images/minimize-button.png"
                        className="img-fluid"
                        alt=""
                      />
                    </button>
                    {teacher_img && (
                      <a
                        href={teacher_resouce_link}
                        className="teacher-button"
                        target="_blank"
                      >
                        <img src={teacher_img} className="img-fluid" alt="" />
                      </a>
                    )}
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6">
                  <div className="dropdown-wrap">
                    <div className="arrowbox">
                      <button
                        className={
                          count == 0 ? "disabled_arrowL" : "arrow-buttonL"
                        }
                        onClick={this.handlePreviousChapter}
                      ></button>
                    </div>

                    <div className="dropdown drop-button">
                      <div
                        className="btn dropdown-toggle selectedChapter"
                        data-toggle="dropdown"
                      >
                        {isIframeNextContent
                          ? chapterContent[count].name
                          : chapter_name}
                      </div>
                      <div className="dropdown-menu">
                        {/*<div className="dropdown-item">
                         <h2>{el == "null" ? "" : el}</h2> 
                      </div>*/}
                        {categoryData &&
                          Object.keys(newCatData).map((el, ind) => (
                            <div className="dropdown-item" key={ind}>
                              <ul className="sub-dropdown">
                                <li>{el == "null" ? "" : el}</li>
                                {newCatData[el].map((item) => (
                                  <li key={item._id}>
                                    <button
                                      onClick={() =>
                                        this.handleViewchapter(
                                          item.use_template,
                                          item.name,
                                          item._id,
                                          item.template_type
                                        )
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="arrowbox">
                      <button
                        className={
                          count == chapterContent.length - 1
                            ? "disabled_arrowR"
                            : "arrow-buttonR"
                        }
                        onClick={this.handleNextChapter}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="main-wrapper1">
            <div className="container-fluid">
              <div className="row">
                <div
                  className="col-lg-10 col-md-12 col-sm-12 offset-lg-1"
                  id="resourceContent"
                >
                  <div className="right-panel">
                    <div className="videoBox scrollBar">
                      {use_template && isIframeNextContent
                        ? renderTypeContent(chapterContent[count].template_type)
                        : renderTypeContent(template_type)}
                    </div>
                  </div>
                </div>
                <div className="col-lg-2" id="toolBarContent">
                  <div id="team_score_counter"></div>
                  <div id="tool_btn"></div>
                </div>
              </div>
            </div>
          </section>
          <Loader isApiPopUp={isApiPopUp} />
        </div>
      );
  }
}
export default Viewchapter;
