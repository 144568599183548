import React, { Component } from "react";
import axios from "axios";
import { homepage, header, background_img_type } from "../config";

export default class AddCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      image: "",
      bg: "",
      user_name: "",
      redirect: false,
      errorMsg: "",
      myToken: "",
      errors: "",
      formIsValid: true,
      showMessage: "",
      isMessagePopUp: false,
      isCatgoryAddVisible: false,
      isBtnDisable: false,
      isBtnLoaderShow: false,
      isApiPopUp: false,
      categoryValue: "Select",
      category_name: "",
      isCatgoryListVisible: false,
      categoryList: "",
    };
  }

  handleCourseValidation = () => {
    const { name, image, bg } = this.state;
    let errors = {};
    let formIsValid = true;
    this.setState({ errors });
    if (name == "") {
      formIsValid = false;
      errors["name"] = "Course name is required !";
    } else if (image == "") {
      formIsValid = false;
      errors["image"] = "Course image is require !";
    } else if (bg == "") {
      errors["bg"] = "Course background image is require !";
      formIsValid = false;
    }

    this.setState({ formIsValid });
    return formIsValid;
  };

  resetForm = () => {
    this.setState({
      name: "",
      image: "",
      bg: "",
      category_name: "",
    });
  };

  handleAddCourse = (e) => {
    e.preventDefault();
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      if (this.handleCourseValidation()) {
        this.setState({
          isBtnDisable: true,
          isBtnLoaderShow: true,
          isApiPopUp: true,
        });

        const { name, image, bg } = this.state;
        token = token.substring(1, token.length - 1);
        const formData = new FormData();
        formData.append("name", name);
        formData.append("image", image);
        formData.append("bg", bg);

        axios
          .post(`${homepage.addCourse}?c=${today}`, formData, {
            headers: header,
          })
          .then((response) => {
            this.props.handleClose();
            this.resetForm();
            this.props.getCourseApi();

            this.setState({
              isMessagePopUp: true,
              showMessage: response.data.message,
              isBtnDisable: false,
              isBtnLoaderShow: false,
              isApiPopUp: false,
            });
            setTimeout(
              () => this.setState({ showMessage: "", isMessagePopUp: false }),
              2000
            );
          })
          .catch((error) => {
            this.setState({
              isMessagePopUp: true,
              showMessage: "Course not add, face server issue",
              isApiPopUp: false,
              isBtnDisable: false,
              isBtnLoaderShow: false,
            });

            if (error.response != undefined && error.response.status == 401) {
              this.setState({ redirect: true });
            }
            setTimeout(
              () =>
                this.setState({
                  showMessage: "",
                  isMessagePopUp: false,
                }),
              2000
            );
          });
      }
    }
  };

  handleName = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: value });
  };

  handleImage = (e) => {
    this.setState({ image: e.target.files[0] });
  };

  handleBg = (e) => {
    this.setState({ bg: e.target.files[0] });
  };

  render() {
    const {
      isAddCourseVisible,
      name,
      image,
      bg,
      redirect,
      errorMsg,
      errors,
      formIsValid,
      showMessage,
      isMessagePopUp,
      isBtnDisable,
      isBtnLoaderShow,
      isApiPopUp,
    } = this.state;
    const error_text = { color: "red" };

    return (
      <React.Fragment>
        {this.props.isAddCourseVisible && (
          <div className="modal" id="addCourse" style={{ display: "block" }}>
            <div className="modal-dialog addPopup modal-md">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">Add Course</h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.props.handleClose}
                  >
                    &times;
                  </button>
                </div>
                <div className="modal-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>
                          Course Name{" "}
                          <span className="text-danger error-text">
                            {!formIsValid && errors.name}
                          </span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="name"
                          value={name}
                          onChange={this.handleName}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>
                          Course Image
                          <span className="text-danger error-text">
                            {!formIsValid && errors.image}
                          </span>
                        </label>{" "}
                        <input
                          type="file"
                          name="image"
                          accept={background_img_type}
                          onChange={this.handleImage}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label>
                          Course Background
                          <span className="text-danger error-text">
                            {!formIsValid && errors.bg}
                          </span>
                        </label>{" "}
                        <input
                          type="file"
                          name="bg"
                          accept={background_img_type}
                          onChange={this.handleBg}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 button-group">
                      <button
                        type="button"
                        className="btn btn-loaderButton"
                        onClick={this.handleAddCourse}
                        disabled={isBtnDisable}
                      >
                        <span className="btn-text">Submit</span>
                        <span className="btn-loder">
                          {isBtnLoaderShow && (
                            <i className="fas fa-spinner"></i>
                          )}
                        </span>
                      </button>
                      <button
                        type="button"
                        className="btn btn-cancel"
                        onClick={this.resetForm}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isMessagePopUp && (
          <div className="loader-popup">
            <div className="popup-text">
              <span>
                <i className="far fa-check-circle"></i>
              </span>
              <h2>Success</h2>
              <p>{showMessage}</p>
            </div>
          </div>
        )}

        {isApiPopUp && (
          <div className="loader-popup">
            <div className="loader">
              <i className="fas fa-spinner"></i>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
