import React, { Component } from "react";
import Footer from "./footer";
import Header from "./header";
import axios from "axios";
import { userpage, header,jsonHeader } from "../config";

var today = Math.round(new Date().getTime() / 1000);
class Userprofile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      name: "",
      image: "",
      phone: "",
      userData: "",
      password: "",
      current_password: "",
      confirm_password: "",
      formIsValid: true,
      isMessagePopUpError: false,
      errors: "",
      showMessage: "",
      isMessagePopUp: false,
    };
  }

  getUserProfile = (user_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
      token = token.substring(1, token.length - 1);
      

      axios
        .get(userpage.getUserProfile + "/" + `${user_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({ userData: res.data.data });
           this.setState({
                errors: "",
                name: res.data.data.name != "null" ? res.data.data.name : "",
                email: res.data.data.name != "null" ? res.data.data.email : "",
                image: res.data.data.name != "null" ? res.data.data.image : "",
                phone: res.data.data.name != "null" ? res.data.data.phone : "",
            });
        })
        .catch((error) => {
          console.log(error);
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  componentDidMount() {
    if (localStorage.getItem("user_id")) {
      var userid = localStorage.getItem("user_id");
      userid = userid.substring(1, userid.length - 1);
      this.setState({ userid: userid });
      this.getUserProfile(userid);
    }
  }

  handleChange = (e) =>{
    const { value, name } = e.target;
    this.setState({ [name]: value });
  }
   handleUserImage = (e) => {
    const { value, name } = e.target;
    this.setState({ [name]: e.target.files[0] });
  };

 
  handleUserProfileValidation = () => {
    const { name, phone } = this.state;
    let errors = {};

    let formIsValid = true;
    this.setState({ errors });
    console.log("User name", name);
    if (name == "") {
      formIsValid = false;
      errors["name"] = "Name is required!";
    } else if (phone === "") {
      formIsValid = false;
      errors["phone"] = "Mobile number is required !";
    }

    this.setState({ formIsValid });
    return formIsValid;
  };
  handleProfileUpdate = () => {
    if (this.handleUserProfileValidation()) {
      var token = localStorage.getItem("accessToken");
      if (token) {
        token = token.substring(1, token.length - 1);
        const { userid, name, phone, image } = this.state;
        const formData = new FormData();
        formData.append("userid", userid);
        formData.append("name", name);
        formData.append("phone", phone);
        formData.append("image", image);
      
        axios
          .post(`${userpage.userProfileUpdate}?c=${today}`, formData, {
            headers: header,
          })
          .then((response) => {
            this.getUserProfile(userid);
            this.setState({
              isMessagePopUp: true,
              showMessage: "Update profile successfully",
            });


            setTimeout(
              () => this.setState({ showMessage: "", isMessagePopUp: false }),
              2000
            );
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Profile not update ",
            });
            setTimeout(
              () =>
                this.setState({ showMessage: "", isMessagePopUpError: false }),
              2000
            );
          });
      } else {
        //this.setState({ redirect: true });
      }
    }
  };

  handleCurrentPasswordTxt = (e) => {
    this.setState({ current_password: e.target.value });
  };
  handlePasswordTxt = (e) => {
    this.setState({ password: e.target.value });
  };
  handleConfirmPasswordTxt = (e) => {
    this.setState({ confirm_password: e.target.value });
  };
  changePasswordValidation = () => {
    const { current_password, password, confirm_password } = this.state;
    let errors = {};

    let formIsValid = true;
    this.setState({ errors });
    if (current_password === "") {
      formIsValid = false;
      errors["current_password"] = "Please enter your current password!";
    } else if (password === "") {
      formIsValid = false;
      errors["password"] = "Please enter your password !";
    } else if (confirm_password === "") {
      errors["confirm_password"] = "Please enter your confirm password !";
      formIsValid = false;
    } else if (confirm_password !== password) {
      errors["confirm_password"] = "Password not match !";
      formIsValid = false;
    }

    this.setState({ formIsValid });
    return formIsValid;
  };

  handleChangePassword = () => {
    if (this.changePasswordValidation()) {
      var token = localStorage.getItem("accessToken");
      if (token) {
        token = token.substring(1, token.length - 1);
        const {
          userid,
          current_password,
          password,
          confirm_password,
        } = this.state;
        const formData = {
          userid: userid,
          current_password: current_password,
          password: password,
          confirm_password: confirm_password,
        };
       
        axios
          .post(`${userpage.userPasswordChange}?c=${today}`, formData, {
            headers: jsonHeader,
          })
          .then((response) => {
            this.setState({
              isMessagePopUp: true,
              showMessage: "Change password successfully",
            });
            setTimeout(
              () => this.setState({ showMessage: "", isMessagePopUp: false }),
              2000
            );
          })
          .catch((error) => {
            this.setState({
              isMessagePopUpError: true,
              showMessage: "Password not change",
            });
            setTimeout(
              () =>
                this.setState({ showMessage: "", isMessagePopUpError: false }),
              2000
            );
          });
      }
    }
  };
  render() {

    const {name,image,
      email,
      phone,
     
      isMessagePopUpError,
      formIsValid,
      errors,
      showMessage,
      isMessagePopUp,
    } = this.state;
    const error_text = { color: "red" };
    return (
      <div>
        <Header />
        <section className="main-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5">
                <div className="left-paanel userProfileLeft">
                  <div className="courseImage">
                    <img src={image} className="img-fluid" alt="" />
                  </div>
                  <div className="courseContent">
                    <h2>{name}</h2>
                    <p>{email}</p>
                  </div>
                  <div className="courseType">
                    <ul className="nav nav-tabs">
                      <li>
                        <button className="active" data-toggle="tab" href="#profile">
                          <span className="profile-icon">
                            <i className="far fa-user"></i>
                          </span>
                          Profile
                        </button>
                      </li>
                      <li>
                        <button data-toggle="tab" href="#changePassword">
                          <span className="profile-icon">
                            <i className="fas fa-unlock-alt"></i>
                          </span>
                          Change Password
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 col-md-7 col-sm-7">
                <div className="right-panel tab-content">
                  <div id="profile" className="tab-pane in active">
                    <div className="title-heading">
                      <h2>Profile</h2>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Name{" "}
                            <span className="text-danger error-text">
                              {!formIsValid && errors.name}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                           
                            value={name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Image</label>
                          <input
                            type="file"
                            name="image"
                            onChange={this.handleUserImage}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>Email Address</label>
                          <input
                            type="text"
                            className="form-control"
                            name="email"
                            value={email}
                            disabled="disabled"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Mobile Number{" "}
                            <span className="text-danger error-text">
                              {!formIsValid && errors.phone}
                            </span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            defaultValue={phone}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 button-group">
                        <button
                          type="button"
                          className="btn btn-submit"
                          onClick={this.handleProfileUpdate}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <div id="changePassword" className="tab-pane fade">
                    <div className="title-heading">
                      <h2>Change Password</h2>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Current Password{" "}
                            <span style={error_text}>
                              {!formIsValid && errors.current_password}
                            </span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="current_password"
                            onChange={this.handleCurrentPasswordTxt}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            New Password{" "}
                            <span className="text-danger error-text">
                              {!formIsValid && errors.password}
                            </span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={this.handlePasswordTxt}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label>
                            Confirm Password{" "}
                            <span className="text-danger error-text">
                              {!formIsValid && errors.confirm_password}
                            </span>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="confirm_password"
                            onChange={this.handleConfirmPasswordTxt}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 button-group">
                        <button
                          type="button"
                          className="btn btn-submit"
                          onClick={this.handleChangePassword}
                        >
                          Submit
                        </button>
                        <button type="button" className="btn btn-cancel">
                          Reset
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isMessagePopUp && (
          <div className="loader-popup">
            <div className="popup-text">
              <span>
                <i className="far fa-check-circle"></i>
              </span>
              <h2>Success</h2>
              <p>{showMessage}</p>
            </div>
          </div>
        )}
        {isMessagePopUpError && (
          <div className="loader-popup">
            <div className="popup-text">
              <span>
                <i className="far fa-times-circle text-danger"></i>
              </span>
              <h2>Error</h2>
              <p>{showMessage}</p>
            </div>
          </div>
        )}
        <Footer />
      </div>
    );
  }
}
export default Userprofile;