import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router";
import { topicpage, header, configpage } from "../config";
import Loader from "../modal/loader";
import Footer from "./footer";

var today = Math.round(new Date().getTime() / 1000);

class ViewCourse extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleList: "",
      background: "",
      image: "",
      id: "",
      bg: "",
      courseTopic: "",
      redirect: false,
      footer_img: "",
      teacher_img: "",
      teacher_resouce_link: "",
      courseid: "",
      left_position: "",
      top_position: "",
      readerData: "",
      licence_agreement: "",
      term_condition: "",
      copy_right: "",
      license_agreement_img: "",
      terms_condition_img: "",
      acknowledgement_link: "",
      acknowledgement_link_image: "",
      isApiPopUp: false,
    };
  }

  getCourseTopic = (course_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
      this.setState({ isApiPopUp: true });
      axios
        .get(topicpage.getTopic + "/" + `${course_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          this.setState({ courseTopic: res.data.data, isApiPopUp: false });
        })
        .catch((error) => {
          this.setState({ isApiPopUp: false });
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  /*Get Config Information*/
  getConfigData = (course_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
      this.setState({ isApiPopUp: true });
      axios
        .get(configpage.getConfig + "/" + `${course_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          if (res.data != null) {
            this.setState({
              footer_img: res.data.data.footer_img,
              footer_img_position: res.data.data.footer_img_position,
              teacher_img: res.data.data.teacher_resouce_img,
              teacher_resouce_link: res.data.data.teacher_resouce_link,
              top_position: res.data.data.top_position,
              left_position: res.data.data.left_position,
              licence_agreement: res.data.data.license_agreement,
              term_condition: res.data.data.terms_condition,
              copy_right: res.data.data.footer_text,
              license_agreement_img: res.data.data.license_agreement_img,
              terms_condition_img: res.data.data.terms_condition_img,
              acknowledgement_link: res.data.data.acknowledgement_link,
              acknowledgement_link_image:
                res.data.data.acknowledgement_link_image,
              isApiPopUp: false,
            });
          }
        })
        .catch((error) => {
          this.setState({ isApiPopUp: false });
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };
  /*End of Config API*/

  componentWillMount() {
    if (this.props.location.state) {

      var course_id = this.props.location.state.courseid;
      var bg_image = this.props.location.state.bg;

      this.setState({
        course_id: course_id,
        background: bg_image,
        image: this.props.location.state.image,
      });
    }
  }

  componentDidMount() {
    var token = localStorage.getItem("accessToken");
    const { course_id, background } = this.state;
    if (token) {
      document.body.style.backgroundImage = "url(" + background + ")";
      document.body.style.backgroundSize = "100%";
      document.body.style.backgroundRepeat = "no-repeat";
      this.getCourseTopic(course_id);
      this.getConfigData(course_id);
    } else {
      this.setState({ redirect: true });
    }
  }

  handleViewlesson = (topic_id, bg_image, course_id) => {
    this.props.history.push("/lessons", { topic_id, bg_image, course_id });
  };

  handleBackBtn = () => {
    this.props.history.push("/");
  };

  handleCourseLists = (topic_id, course_id, bg_image) => {
    this.props.history.push("/readerlists", { topic_id, course_id, bg_image });
  };

  render() {
    const {
      courseTopic,
      redirect,
      footer_img,
      teacher_img,
      teacher_resouce_link,
      course_id,
      licence_agreement,
      term_condition,
      top_position,
      left_position,
      license_agreement_img,
      terms_condition_img,
      copy_right,
      acknowledgement_link,
      isApiPopUp,
      acknowledgement_link_image,
    } = this.state;
    let style = "";

    if (top_position || left_position) {
      style = {
        position:
          top_position != "0px" || left_position != "0px" ? "fixed" : "",
        right:
          left_position && left_position != "0px" ? `${left_position}px` : "",
        bottom:
          top_position && top_position != "0px" ? `${top_position}px` : "",
      };
    }

    const footerCourseId =
      Array.isArray(courseTopic) &&
      courseTopic.filter((item) => item.btn_position == "footer" && item);

    return redirect ? (
      <Redirect to={"/login"} />
    ) : (
        <div>
          <section className="top-wrapper" id="topWrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8">
                  <div className="left-button">
                    <button
                      onClick={this.handleBackBtn}
                      className="action-button"
                    >
                      <img src="images/close.png" className="img-fluid" alt="" />
                    </button>
                    <button className="action-button">
                      <img
                        src="images/minimize-button.png"
                        className="img-fluid"
                        alt="It's web version, so not working"
                      />
                    </button>
                    {teacher_img && (
                      <a
                        href={teacher_resouce_link}
                        className="teacher-button"
                        target="_blank"
                      >
                        <img src={teacher_img} className="img-fluid" alt="" />
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="main-wrapper1">
            <div className="container">
              <div className="row">
                <div className="col-xl-12 mt-30">
                  <div className="row">
                    <div className="col-md-3 text-center">
                      <div className="topicImage">
                        <img
                          src={this.state.image}
                          className="img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-md-9 topicButton">
                      <div className="unitList scrollBar">
                        <div className="row">
                          <div className="col-md-6">
                            {/*color: item.btn_color,*/}

                            {Array.isArray(courseTopic) &&
                              courseTopic.map(
                                (item) =>
                                  item.btn_position == "left" && (
                                    <button
                                      key={item._id + "1"}
                                      type="button"
                                      className="unit-1 unit-2"
                                      style={{
                                        width: item.btn_width + "px",
                                        height: item.btn_height + "px",
                                        backgroundSize: "cover",
                                        color: item.btn_font_color,
                                        fontSize: item.btn_font_size + "px",

                                        background:
                                          item.btn_image != "" &&
                                            item.btn_image != null &&
                                            item.btn_image != "null"
                                            ? "url(" +
                                            item.btn_image +
                                            ") no-repeat top center " +
                                            item.btn_color
                                            : " " + item.btn_color,
                                      }}
                                      onClick={() =>
                                        this.handleViewlesson(
                                          item._id,
                                          item.bg_image,
                                          course_id
                                        )
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  )
                              )}
                          </div>
                          {/*style={{background: item.btn_color}}*/}
                          <div className="col-md-6">
                            {Array.isArray(courseTopic) &&
                              courseTopic.map(
                                (item) =>
                                  item.btn_position == "right" && (
                                    <button
                                      type="button"
                                      className="unit-1"
                                      key={item._id + "2"}
                                      style={{
                                        color: item.btn_color,
                                        width: item.btn_width + "px",
                                        height: item.btn_height + "px",
                                        backgroundSize: "cover",
                                        color: item.btn_font_color,
                                        fontSize: item.btn_font_size + "px",
                                        background:
                                          item.btn_image != "" &&
                                            item.btn_image != null &&
                                            item.btn_image != "null"
                                            ? "url(" +
                                            item.btn_image +
                                            ") no-repeat top center " +
                                            item.btn_color
                                            : " " + item.btn_color,
                                      }}
                                      onClick={() =>
                                        this.handleViewlesson(
                                          item._id,
                                          item.bg_image,
                                          course_id
                                        )
                                      }
                                    >
                                      {item.name}
                                    </button>
                                  )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    {Array.isArray(courseTopic) &&
                      courseTopic.map(
                        (item) =>
                          item.btn_position === "footer" && (
                            <>
                              {footer_img && (
                                <div
                                  key={footerCourseId[0]._id + "0"}
                                  className="reader-button"
                                  style={{
                                    position:
                                      top_position || left_position
                                        ? "relative"
                                        : "",
                                  }}
                                >
                                  <a
                                    onClick={() =>
                                      this.handleCourseLists(
                                        footerCourseId[0]._id,
                                        footerCourseId[0].course_id,
                                        footerCourseId[0].bg_image
                                      )
                                    }
                                    style={style}
                                  >
                                    <img
                                      src={footer_img}
                                      className="img-fluid"
                                      alt=""
                                    />
                                  </a>
                                </div>
                              )}
                            </>
                          )
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Loader isApiPopUp={isApiPopUp} />
          <Footer
            term_condition={term_condition}
            licence_agreement={licence_agreement}
            copy_right={copy_right}
            license_agreement_img={license_agreement_img}
            terms_condition_img={terms_condition_img}
            acknowledgement_link={acknowledgement_link}
            acknowledgement_link_image={acknowledgement_link_image}
          />
        </div>
      );
  }
}
export default ViewCourse;
