import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import history from "./history";
import Home from "./containers/home";
import Login from "./containers/login";
import Userprofile from "./containers/userprofile";
import Coursedetails from "./containers/coursedetails";
import Viewcourse from "./containers/viewcourse";
import Viewlesson from "./containers/viewlesson";
import Viewchapter from "./containers/viewchapter";
import Viewcategory from "./containers/Viewcategory";
import CourseLists from "./containers/courseList";
// import Viewresource from "./containers/viewresource";

/*const Userprofile   = React.lazy(() => import("./containers/userprofile"));
const Coursedetails = React.lazy(() => import("./containers/coursedetails"));
const Viewcourse    = React.lazy(() => import("./containers/viewcourse"));
const Viewlesson    = React.lazy(() => import("./containers/viewlesson"));
const Viewchapter   = React.lazy(() => import("./containers/viewchapter"));
const Viewcategory  = React.lazy(() => import("./containers/Viewcategory"));
const CourseLists   = React.lazy(() => import("./containers/courseList"));*/


const App = () => {   
  return (
    <div className="wrapper">
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/user-profile" component={Userprofile} />
          <Route exact path="/course-details" component={Coursedetails} />
          <Route exact path="/course" component={Viewcourse} />
          <Route exact path="/lessons" component={Viewlesson} />
          <Route exact path="/chapters" component={Viewchapter} />
          {/* <Route exact path="/resource" component={Viewresource} /> */}
          <Route exact path="/category" component={Viewcategory} />
          <Route exact path="/readerlists" component={CourseLists} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
