import React, { Component } from "react";
import axios from "axios";
import $ from "jquery";
import {
  configpage,
  header,
  jsonHeader,
  lessonpage,
  document_img_type,
} from "../config";

import Loader from "../modal/loader";
import ShowMessage from "../modal/showmessage";
import ImageUpload from "../shared/components/FormElements/ImageUpload";
class ViewConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config_id: "",
      course_id: "",
      version: "",
      package_id: "",
      language: "",
      rest_url: "",
      update_request: "",
      teacher_resouce_img: "",
      teacher_resouce_link: "",
      number_picker: "",
      score_counter: "",
      footer_text: "",
      license_agreement_img: "",
      license_agreement: "",
      terms_condition_img: "",
      terms_condition: "",
      top_position: "",
      left_position: "",
      footer_img: "",
      errors: "",
      formIsValid: true,
      packageIdData: "",
      isBtnDisable: false,
      license_agreement_img_thumb: "",
      teacher_resource_img_thumb: "",
      terms_condition_img_thumb: "",
      footer_img_thumb: "",
      acknowledgement_link: "",
      acknowledgement_link_image: "",
      acknowledgement_link_image_thumb: "",
      errorMessage: "",
      showMessage: "",
      isApiPopUp: false,
      isMessagePopUp: false,
      isMessagePopUpError: false,
    };
  }

  componentWillMount() {
    const course_id = localStorage.getItem("course_id");
    this.setState({ course_id });
  }

  componentDidMount() {
    const { course_id } = this.state;
    this.getPackageData();
    this.getConfigData(course_id);
  }

  getConfigData = (courseid) => {
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);

    if (token) {
      axios
        .get(configpage.getConfig + "/" + `${courseid}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          if (res) {
            this.setState({
              config_id: res.data.data._id,
              version: res.data.data.version,
              package_id: res.data.data.package_id,
              language: res.data.data.language,
              rest_url: res.data.data.rest_url,
              update_request: res.data.data.update_request,
              init_screen_videoc: res.data.data.init_screen_video,
              bg_imagec: res.data.data.bg_image,
              bg_soundc: res.data.data.bg_sound,
              config_sound_level: res.data.data.bg_sound_level,
              bg_image: res.data.data.bg_image,
              footer_img_thumb: res.data.data.footer_img,
              footer_img: res.data.data.footer_img,
              footer_img_position: res.data.data.footer_img_position,

              teacher_resource_img_thumb:
                res.data.data.teacher_resource_img_thumb,
              teacher_resource_img_thumb: res.data.data.teacher_resouce_img,
              teacher_img: res.data.data.teacher_img,
              teacher_resouce_link: res.data.data.teacher_resouce_link,

              score_counter:
                res.data.data.score_counter != undefined
                  ? res.data.data.score_counter
                  : "No",
              acknowledgement_link:
                res.data.data.acknowledgement_link != undefined
                  ? res.data.data.acknowledgement_link
                  : "",
              number_picker:
                res.data.data.number_picker != undefined
                  ? res.data.data.number_picker
                  : "No",

              terms_condition: res.data.data.terms_condition,
              license_agreement: res.data.data.license_agreement,
              footer_text: res.data.data.footer_text,
              top_position: res.data.data.top_position,
              left_position: res.data.data.left_position,

              acknowledgement_link_image_thumb:
                res.data.data.acknowledgement_link_image,
              terms_condition_img: res.data.data.terms_condition_img,
              terms_condition_img_thumb: res.data.data.terms_condition_img,

              license_agreement_img: res.data.data.license_agreement_img,
              license_agreement_img_thumb: res.data.data.license_agreement_img,

              copyright_img: res.data.data.copyright_img,
              copyright_img_thumb: res.data.data.copyright_img,
            });
          }
        })
        .catch((error) => {
          if (error.response != undefined && error.response.status == 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  getPackageData = async () => {
    var today = Math.round(new Date().getTime() / 1000);
    try {
      this.setState({ isApiPopUp: false });
      const resp = await axios.get(`${configpage.get_packages}?c=${today}`, {
        headers: jsonHeader,
      });
      if (resp.status == 200) {
        this.setState({ packageIdData: resp.data.data, isApiPopUp: false });
      }
    } catch (error) {
      this.setState({ isApiPopUp: false });
    }
  };

  handleConfigValidation = () => {
    const {
      version,
      package_id,
      rest_url,
      update_request,
      bg_image,
      language,
      left_position,
      top_position,
    } = this.state;
    let errors = {};

    let formIsValid = true;

    var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

    this.setState({ errors });
    if (version == "") {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      formIsValid = false;
      errors["version"] = "Course version is required!";
    } else if (package_id == "") {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      formIsValid = false;
      errors["package_id"] = "Package Id is required !";
    } else if (language == "") {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      formIsValid = false;
      errors["language"] = "Language is required !";
    } else if (!regex.test(rest_url) || rest_url == "") {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      errors["rest_url"] = "Url is required !";
      formIsValid = false;
    } else if (!regex.test(update_request) || update_request == "") {
      $("html, body").animate({ scrollTop: 0 }, "slow");
      errors["update_request"] = "url is required !";
      formIsValid = false;
    } else if (top_position == "") {
      $("html, body").animate({ scrollTop: 400 }, "slow");
      errors["top_position"] = "Top Position Value  required !";
      formIsValid = false;
    } else if (left_position == "") {
      $("html, body").animate({ scrollTop: 400 }, "slow");
      errors["left_position"] = "Left Position Value required !";
      formIsValid = false;
    }

    this.setState({ formIsValid });
    return formIsValid;
  };

  handleChangePackageId = () => {
    const { package_id } = this.state;
    var today = Math.round(new Date().getTime() / 1000);
    axios
      .get(lessonpage.check_package_id + "/" + `${package_id}?c=${today}`, {
        headers: header,
      })
      .then((res) => {
        if (res) {
          this.setState({
            isApiPopUp: false,
            isMessagePopUp: true,
            showMessage: res.data.message,
          });
          setTimeout(
            () =>
              this.setState({
                showMessage: "",
                isMessagePopUp: false,
              }),
            4000
          );
        }
      })
      .catch((error) => {
        this.setState({
          isMessagePopUpError: true,
          isApiPopUp: false,
          showMessage: error.response.data.message,
        });
        if (error.response != undefined && error.response.status == 401) {
          this.setState({ redirect: true });
        }
        setTimeout(
          () =>
            this.setState({
              showMessage: "",
              isMessagePopUpError: false,
            }),
          4000
        );
      });
  };

  handleAddConfig = (e) => {
    e.preventDefault();
    var token = localStorage.getItem("accessToken");
    var today = Math.round(new Date().getTime() / 1000);
    if (token) {
      token = token.substring(1, token.length - 1);

      if (this.handleConfigValidation()) {
        this.setState({ isBtnDisable: true, isApiPopUp: true });
        const {
          config_id,
          course_id,
          version,
          language,
          package_id,
          rest_url,
          update_request,
          config_sound_level,
          bg_image,
          init_screen_video,
          bg_sound,
          footer_img,
          footer_img_position,
          teacher_resouce_img,
          teacher_resouce_link,
          score_counter,
          number_picker,
          left_position,
          top_position,
          terms_condition,
          license_agreement,
          footer_text,
          terms_condition_img,
          license_agreement_img,
          acknowledgement_link,
          acknowledgement_link_image,
          copyright_img,
        } = this.state;
        const formData = new FormData();
        formData.append("course_id", course_id);
        formData.append("language", language);
        formData.append("version", version);
        formData.append("package_id", package_id);
        formData.append("rest_url", rest_url);
        formData.append("update_request", update_request);
        formData.append("bg_sound_level", config_sound_level);
        formData.append("bg_image", bg_image);
        formData.append("init_screen_video", init_screen_video);
        formData.append("bg_sound", bg_sound);

        formData.append("footer_img", footer_img);
        formData.append("footer_img_position", footer_img_position);

        formData.append("teacher_resouce_img", teacher_resouce_img);
        formData.append("teacher_resouce_link", teacher_resouce_link);

        formData.append("score_counter", score_counter);
        formData.append("number_picker", number_picker);
        formData.append("left_position", left_position);
        formData.append("top_position", top_position);

        formData.append("terms_condition", terms_condition);
        formData.append("license_agreement", license_agreement);
        formData.append("footer_text", footer_text);

        formData.append("terms_condition_img", terms_condition_img);
        formData.append("license_agreement_img", license_agreement_img);
        formData.append("copyright_img", copyright_img);
        formData.append("acknowledgement_link", acknowledgement_link);
        formData.append(
          "acknowledgement_link_image",
          acknowledgement_link_image
        );

        if (config_id) {
          formData.append("id", config_id);

          this.setState({
            showMessage: "",
            isMessagePopUp: false,
            isApiPopUp: false,
          });
          axios
            .post(`${configpage.updateConfig}?c=${today}`, formData, {
              headers: header,
            })
            .then((response) => {
              this.getConfigData(course_id);
              this.setState({
                isMessagePopUp: true,
                showMessage: "Update config successfully",
                isBtnDisable: false,
                isApiPopUp: false,
              });
              setTimeout(
                () =>
                  this.setState({
                    showMessage: "",
                    isMessagePopUp: false,
                    isApiPopUp: false,
                  }),
                2000
              );
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                errorMessage: error.response.data.message,
                isApiPopUp: false,
                isBtnDisable: false,
              });
              setTimeout(
                () =>
                  this.setState({
                    errorMessage: "",
                    isMessagePopUpError: false,
                    isApiPopUp: false,
                  }),
                3000
              );
            });
        } else {
          this.setState({ errorMessage: "" });

          axios
            .post(`${configpage.addConfig}?c=${today}`, formData, {
              headers: header,
            })
            .then((response) => {
              this.getConfigData(course_id);

              this.setState({
                isMessagePopUp: true,
                showMessage: "Add config successfully",
                isBtnDisable: false,
                isApiPopUp: false,
              });
              setTimeout(
                () => this.setState({ showMessage: "", isMessagePopUp: false }),
                2000
              );
            })
            .catch((error) => {
              this.setState({
                isMessagePopUpError: true,
                errorMessage: error.responsee && error.response.data.message,
                isBtnDisable: false,
                isApiPopUp: false,
              });
              setTimeout(
                () =>
                  this.setState({
                    errorMessage: "",
                    isMessagePopUpError: false,
                  }),
                3000
              );
            });
        }
      }
    } else {
      this.setState({ redirect: true });
    }
  };

  handleChangeConfig = (event) => {
    const { name, value } = event.target;

    if (name == "package_id" && value == "") {
      this.setState({ [name]: value });
    } else if (name == "package_id" && value != "") {
      this.setState({ isApiPopUp: true, [name]: value }, () =>
        this.handleChangePackageId()
      );
    } else {
      this.setState({ [name]: value });
    }
  };

  handleTeacherResImageChange = (e) => {
    this.setState({ teacher_resouce_img: e.target.files[0] });
  };

  handleImageChange = (e) => {
    this.setState({ footer_img: e.target.files[0] });
  };

  handleAcknowledgementLinkImage = (e) => {
    this.setState({ acknowledgement_link_image: e.target.files[0] });
  };

  handleTermImageChange = (e) => {
    this.setState({ terms_condition_img: e.target.files[0] });
  };

  handleLicenceImageChange = (e) => {
    this.setState({ license_agreement_img: e.target.files[0] });
  };

  render() {
    const {
      version,
      package_id,
      language,
      rest_url,
      update_request,
      teacher_resouce_link,
      number_picker,
      score_counter,
      footer_text,
      license_agreement,
      terms_condition,
      top_position,
      left_position,
      formIsValid,
      errors,
      packageIdData,
      isBtnDisable,
      license_agreement_img_thumb,
      teacher_resource_img_thumb,
      terms_condition_img_thumb,
      footer_img_thumb,
      isApiPopUp,
      errorMessage,
      showMessage,
      isMessagePopUp,
      isMessagePopUpError,
      acknowledgement_link,
      acknowledgement_link_image_thumb,
    } = this.state;

    return (
      <div className="right-panel tab-content">
        <div id="config" className="tab-pane in active">
          <div className="title-heading">
            <h2>Config Section</h2>
          </div>
          <div className="row ">
            <div className="errorId col-lg-12">
              <span style={{ color: "red" }}>{this.state.errorMessage}</span>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Version{" "}
                  <span className="text-danger error-text">
                    {!formIsValid && errors.version}
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="version"
                  value={version}
                  onChange={this.handleChangeConfig}
                />
                <small>Version format should look like 1.0.0</small>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Package Id{" "}
                  <span className="text-danger error-text">
                    {!formIsValid && errors.package_id}
                  </span>
                </label>
                <select
                  className="form-control"
                  name="package_id"
                  value={package_id}
                  onChange={this.handleChangeConfig}
                >
                  <option value="">Select Package Id</option>
                  {Array.isArray(packageIdData) && packageIdData.length > 0
                    ? packageIdData.map((item, index) => (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      ))
                    : ""}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Language
                  <span className="text-danger error-text">
                    {!formIsValid && errors.language}
                  </span>
                </label>
                <select
                  className="form-control"
                  name="language"
                  value={language}
                  onChange={this.handleChangeConfig}
                >
                  <option value="0">Select Language</option>
                  <option value="English">English</option>
                  <option value="Simplified-chinese">Simplified-chinese</option>
                  <option value="Traditional-chinese">
                    Traditional-chinese
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Activation URL
                  <span className="text-danger error-text">
                    {!formIsValid && errors.rest_url}
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={rest_url}
                  name="rest_url"
                  onChange={this.handleChangeConfig}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>
                  Update URL
                  <span className="text-danger error-text">
                    {!formIsValid && errors.update_request}
                  </span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="update_request"
                  value={update_request}
                  onChange={this.handleChangeConfig}
                />
              </div>
            </div>

            <div className="divider"></div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>
                    Teacher Resource Image
                    <span className="text-danger error-text">
                      {!formIsValid && errors.teacher_resouce_img}
                    </span>
                  </label>
                  <input
                    type="file"
                    name="teacher_resouce_img"
                    onChange={this.handleTeacherResImageChange}
                    accept={document_img_type}
                  />
                </div>
                {teacher_resource_img_thumb && (
                  <a
                    className="smallImage"
                    href={teacher_resource_img_thumb}
                    target="_blank"
                  >
                    <i className="far fa-image"></i>
                  </a>
                )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Resource Image Link</label>

                  <input
                    type="text"
                    className="form-control"
                    value={teacher_resouce_link}
                    name="teacher_resouce_link"
                    accept={document_img_type}
                    onChange={this.handleChangeConfig}
                  />
                </div>
              </div>
            </div>

            <div className="divider"></div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>
                    Footer Image{" "}
                    <span className="text-danger error-text">
                      {!formIsValid && errors.footer_img}
                    </span>
                  </label>
                  <input
                    type="file"
                    name="footer_img"
                    accept={document_img_type}
                    onChange={this.handleImageChange}
                  />
                </div>
                {footer_img_thumb && (
                  <a
                    className="smallImage"
                    href={footer_img_thumb}
                    target="_blank"
                  >
                    <i className="far fa-image"></i>
                  </a>
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>From Bottom</label>
                  <input
                    type="text"
                    className="form-control"
                    name="top_position"
                    value={top_position}
                    accept={document_img_type}
                    onChange={this.handleChangeConfig}
                  />
                  <span className="text-danger error-text ml-0">
                    {!formIsValid && errors.top_position}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>From Right</label>
                  <input
                    type="text"
                    className="form-control"
                    name="left_position"
                    value={left_position}
                    accept={document_img_type}
                    onChange={this.handleChangeConfig}
                  />
                  <span className="text-danger error-text ml-0">
                    {!formIsValid && errors.left_position}
                  </span>
                </div>
              </div>
            </div>

            <div className="divider"></div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Terms and Condition</label>

                  <textarea
                    rows="3"
                    className="form-control"
                    value={terms_condition}
                    name="terms_condition"
                    minLength={1}
                    maxLength={1024}
                    placeholder="Terms and Condition"
                    onChange={this.handleChangeConfig}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Terms and Condition File</label>
                  <input
                    type="file"
                    name="terms_condition_img"
                    accept={document_img_type}
                    onChange={this.handleTermImageChange}
                  />
                </div>
                {terms_condition_img_thumb && (
                  <a
                    className="smallImage"
                    href={terms_condition_img_thumb}
                    target="_blank"
                  >
                    <i className="far fa-image"></i>
                  </a>
                )}
              </div>
            </div>

            <div className="divider"></div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>License Agreement</label>

                  <textarea
                    rows="3"
                    className="form-control"
                    value={license_agreement}
                    name="license_agreement"
                    minLength={1}
                    maxLength={1024}
                    placeholder="License Agreement"
                    onChange={this.handleChangeConfig}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>License Agreement File</label>
                  <input
                    type="file"
                    accept={document_img_type}
                    name="license_agreement_img"
                    onChange={this.handleLicenceImageChange}
                  />
                </div>
                {license_agreement_img_thumb && (
                  <a
                    className="smallImage"
                    href={license_agreement_img_thumb}
                    target="_blank"
                  >
                    <i className="far fa-image"></i>
                  </a>
                )}
              </div>
            </div>

            <div className="divider"></div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Copyright Text</label>

                  <textarea
                    rows="3"
                    className="form-control"
                    value={footer_text}
                    name="footer_text"
                    minLength={1}
                    maxLength={1024}
                    placeholder="Copyright text"
                    onChange={this.handleChangeConfig}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="divider"></div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Acknowledgement Link</label>

                  <textarea
                    rows="3"
                    className="form-control"
                    value={acknowledgement_link}
                    name="acknowledgement_link"
                    minLength={1}
                    maxLength={1024}
                    placeholder="Acknowledgement Link"
                    onChange={this.handleChangeConfig}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Acknowledgement Link Image</label>
                  <input
                    type="file"
                    name="acknowledgement_link_image"
                    onChange={this.handleAcknowledgementLinkImage}
                    accept={document_img_type}
                  />

                  {/*<ImageUpload 
                    id='acknowledgement_link_image' 
                    name='acknowledgement_link_image' 
                    allowedImgExt={document_img_type}
                  />*/}
                </div>
                {acknowledgement_link_image_thumb && (
                  <a
                    className="smallImage"
                    href={acknowledgement_link_image_thumb}
                    target="_blank"
                  >
                    <i className="far fa-image"></i>
                  </a>
                )}
              </div>
            </div>
            <div className="divider"></div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Enable Score Counter</label>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="score_counter"
                          id="scoreCounterYes"
                          value="Yes"
                          checked={score_counter === "Yes"}
                          onChange={this.handleChangeConfig}
                        />
                        <label
                          className="form-check-label"
                          labelfor="scoreCounterYes"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="score_counter"
                          id="scoreCounterNo"
                          value="No"
                          checked={score_counter === "No"}
                          onChange={this.handleChangeConfig}
                        />
                        <label
                          className="form-check-label"
                          labelfor="scoreCounterYes"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group imageBox">
                <div className="input-Image">
                  <label>Enable Number Picker</label>
                  <div className="row">
                    <div className="col-lg-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="number_picker"
                          id="numberPickerYes"
                          value="Yes"
                          checked={number_picker === "Yes"}
                          onChange={this.handleChangeConfig}
                        />
                        <label
                          className="form-check-label"
                          labelfor="numberPickerYes"
                        >
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="number_picker"
                          id="numberPickerNo"
                          value="No"
                          checked={number_picker === "No"}
                          onChange={this.handleChangeConfig}
                        />
                        <label
                          className="form-check-label"
                          labelfor="numberPickerNo"
                        >
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 button-group">
              <button
                type="button"
                className="btn btn-loaderButton"
                onClick={this.handleAddConfig}
                disabled={isBtnDisable}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <Loader isApiPopUp={isApiPopUp} />
        <ShowMessage
          showMessage={showMessage}
          errorMessage={errorMessage}
          isMessagePopUp={isMessagePopUp}
          isMessagePopUpError={isMessagePopUpError}
        />
      </div>
    );
  }
}

export default ViewConfig;
