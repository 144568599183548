import { siteUrl } from "./siteConfig";

let base_url = siteUrl.site_url;
let domain = base_url + "api/";

var token = localStorage.getItem("accessToken");
if (token) {
  token = token.substring(1, token.length - 1);
} else {
  token = "";
}

let TOKEN_STRING = token;

const header = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
  "x-access-token": TOKEN_STRING,
};

const jsonHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
  "x-access-token": TOKEN_STRING,
};

const loginpage = {
  enabled: true,
  login: domain + "auth/signin",
};

const homepage = {
  enabled: true,
  getCourse: domain + "get-course",
  getCourseById: domain + "get-course-byid",
  addCourse: domain + "add-course",
  updateCourse: domain + "update-course",
  DeleteTopic: domain + "delete-topic",
  DeleteLesson: domain + "delete-lesson",
  DeleteCourse: domain + "delete-course",
  GetCategory: domain + "get-category",
  AddCategory: domain + "add-category",
  getCategory: domain + "get-category",
  updateCategory: domain + "update-category",
  publishCourse: domain + "publish-course",
};

const topicpage = {
  enabled: true,
  getTopic: domain + "get-topic",
  getTopicWithConfig: domain + "get-topic-with-config",
  getTopicById: domain + "get-topic-byid",
  getCourseDetails: domain + "get-course-details",
  addTopic: domain + "add-topic",
  updateTopic: domain + "update-topic",
  checkBtnPos: domain + "check-btn-pos",
};

const lessonpage = {
  enabled: true,
  getLesson: domain + "get-lesson-bytopicid",
  getLessonById: domain + "get-lesson-byid",
  addLesson: domain + "add-lesson",
  updateLesson: domain + "update-lesson",
  sortTopic: domain + "sort-topic",
  sortLesson: domain + "sort-lesson",
  check_package_id: domain + "check-package-id",
  sortChapter: domain + "sort-chapter",
};

const chapterpage = {
  enabled: true,
  getChapterById: domain + "get-chapter-byid",
  getChapterByLessonId: domain + "get-chapter-bylessonid",
  addChapter: domain + "add-chapter",
  updateChapter: domain + "update-chapter",
  deleteChapter: domain + "delete-chapter",
};

const configpage = {
  enabled: true,
  getConfig: domain + "get-config",
  addConfig: domain + "add-config",
  updateConfig: domain + "update-config",
  // get_packages: "http://pre-primary.resources.pprod4.ilongman.com/get_packages",
  get_packages: domain + "get-package-list",
};

const userpage = {
  enabled: true,
  userProfileUpdate: domain + "user-profile-update",
  getUserProfile: domain + "get-user-profile",
  userPasswordChange: domain + "change-user-password",
};

const background_img_type = ".jpg, .jpeg, .png, .gif";

const document_img_type = ".jpg, .jpeg, .pdf";

const button_img_type = ".png";

const video_type = ".mp4";

export {
  loginpage,
  userpage,
  homepage,
  topicpage,
  lessonpage,
  chapterpage,
  configpage,
  header,
  jsonHeader,
  background_img_type,
  button_img_type,
  video_type,
  document_img_type,
  
};
