import React, { useEffect } from "react";

export const ImageType = React.memo(function IframeType(props) {
  // var v = document.getElementById("id_video");
  // var width = v.offsetWidth;
  // v.height = Math.floor(width * (9 / 16)); // dynamically setting video height to maintain aspect ratio

  useEffect(() => {
    // var img = new Image();
    // img.onload = function () {
    //   var myvid = document.querySelector("#myImage");
    //   myvid.style.width = `${this.width}px`;
    //   myvid.style.height = `${this.height}px`;
    //   return `${this.height}px`;
    // };
    // img.src = props.url;
    window.Resize();
    if (props.url) {
      props.loader(false);
    }
  }, [props.url]);

  return (
    <img
      src={props.url}
      className="img-fluid horizon"
      alt=""
      id="myImage"
    ></img>
  );
});
