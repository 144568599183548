import React, { Component } from "react";
import axios from "axios";
import { loginpage, header } from "../config";

var today = Math.round(new Date().getTime() / 1000);

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      token: "",
      errMsg: "",
      isLoginSuccess: false,
    };
  }

  handleUserEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  handleUserPassword = (e) => {
    this.setState({ password: e.target.value });
  };

  userLogIn = (e) => {
    e.preventDefault();

    const { email, password } = this.state;
    var loginData = {
      email: email,
      password: password,
    };
    this.setState({ isLoginSuccess: true });
    const header_new = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT",
    };

    axios
      .post(`${loginpage.login}?c=${today}`, loginData, { headers: header_new })
      .then((response) => {
        if (response.data.data.accessToken) {
          this.setState({
            token: response.data.data.accessToken,
            isLoginSuccess: false,
          });
          localStorage.setItem(
            "accessToken",
            JSON.stringify(response.data.data.accessToken)
          );
          localStorage.setItem("name", JSON.stringify(response.data.data.name));
          localStorage.setItem(
            "user_id",
            JSON.stringify(response.data.data.id)
          );
          localStorage.setItem(
            "phone",
            JSON.stringify(response.data.data.phone)
          );
          localStorage.setItem(
            "email",
            JSON.stringify(response.data.data.email)
          );
          this.props.history.push("/", this.state.token);
        } else {
          this.setState({
            errMsg: response.data.message,
            isLoginSuccess: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errMsg: "Login credentials not exist",
          isLoginSuccess: false,
        });
      });
  };

  render() {
    const { errMsg, isLoginSuccess } = this.state;
    return (
      <div className="login-page">
        <div className="dash_logo">
          <img src="images/logo.png" className="img-fluid" alt="" />
          <h2>Welcome Back!</h2>
          {errMsg ? <font color="red">{errMsg}</font> : ""}
        </div>
        <div className="loginBox">
          <form onSubmit={this.userLogIn}>
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label>Email Address</label>
                  <input
                    type="text"
                    className="form-control"
                    name="email"
                    onChange={this.handleUserEmail}
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label>Password</label>
                  <input
                    type="password"
                    className="form-control"
                    name="password"
                    onChange={this.handleUserPassword}
                  />
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="form-group"></div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                {isLoginSuccess && (
                  <div className="loader-popup">
                    <div className="loader">
                      <i className="fas fa-spinner"></i>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <button
                    onClick={this.userLogIn}
                    disabled={isLoginSuccess}
                    className="btn-login"
                  >
                    Sign In
                  </button>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="forgotPassword">
                  {/*<a >Forgot my password?</a>*/}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default Login;
