import React, { useEffect } from "react";

export const VideoType = React.memo(function IframeType(props) {
  useEffect(() => {
    window.removeVideoContainer();
    window.playerContObj.src.mp4 = props.url;
    window.playerContObj.title = props.title;
    if (props.url) {
      props.loader(false);
    }
  }, [props.url]);

  return (
    <div onLoad={window.loadCommonLibraries()}>
      <div className="main">
        <div id="player_1">
          <div id="playerSection" className="playerSection">
            <div id="playerContainer" className="playerContainer">
              <div id="mediaContainer" className="mediaContainer"></div>
              <div id="closeCaptionTxt" className="closeCaptionTxt">
                <div
                  id="ccTextContainer"
                  className="ccTextContainer vMiddle"
                ></div>
              </div>
              <div id="headerSection" className="headerSection">
                <span id="videoTitle" className="videoTitle"></span>
              </div>
            </div>
            <div id="seekBarContainer" className="seekBarContainer">
              <div id="seekBarProgress" className="seekBarProgress"></div>
              <div id="seekBar" className="seekBar"></div>
              <div id="seekBarClick" className="seekBarClick"></div>
              <div id="seekBarHandle" className="seekBarHandle pointer"></div>
              <div id="seekTimeMin" className="seekTimeMin">
                0:00
              </div>
              <div id="seekTimeMax" className="seekTimeMax">
                0:00
              </div>
            </div>
            <div id="playIconContainer" className="playIconContainer">
              <div id="playIcon2" className="playIcon2 pointer"></div>
            </div>
            <div id="playerNavigations" className="playerNavigations">
              <div id="speedControlContainer" className="speedControlContainer">
                <div className="speedControlTitle">Playback Speed</div>
                <div id="speedBarContainer" className="speedBarContainer">
                  <div id="speedBar" className="speedBar"></div>
                  <div
                    id="speedLeftMark"
                    className="speedLeftMark pointer"
                  ></div>
                  <div
                    id="speedRightMark"
                    className="speedRightMark pointer"
                  ></div>
                  <div
                    id="speedCenterMark"
                    className="speedCenterMark pointer"
                  ></div>
                </div>
                <div className="speedRangeTitles">
                  <span id="speedMinTitle" className="speedMinTitle pointer">
                    70%
                  </span>
                  <span
                    id="speedNormalTitle"
                    className="speedNormalTitle pointer"
                  >
                    Normal
                  </span>
                  <span id="speedMaxTitle" className="speedMaxTitle pointer">
                    130%
                  </span>
                </div>
                <div className="speedBarDragContainer">
                  <div id="speedBarIcon" className="speedBarIcon pointer">
                    <div id="speedDragTxt" className="speedDragTxt">
                      100%
                    </div>
                  </div>
                </div>
              </div>
              <div id="ccMenu" className="ccMenu">
                <div
                  id="ccMenuOpt_0"
                  className="ccMenuItem ccMenuActive pointer"
                >
                  <span className="ccTxt vMiddle">Turn off</span>
                  <div className="vAlign"></div>
                </div>
              </div>
              <div id="playIcon" className="playIcon pointer"></div>
              <div id="audioIcon" className="audioIcon pointer"></div>
              <div id="audioController" className="audioController">
                <div id="audioControllerMenu" className="audioControllerBg">
                  <div id="audioVolProgressBar" className="audioVolProgressBar">
                    <div
                      id="audioCurrentVolume"
                      className="audioCurrentVolume"
                    ></div>
                    <div id="audioSlider" className="audioSlider pointer"></div>
                  </div>
                </div>
              </div>
              <div id="speedIcon1" className="speedIcon pointer">
                <div className="vAlign"></div>
                <div className="speed">100%</div>
                <div className="vAlign"></div>
              </div>
              <div id="timeProgress" className="timeProgress playerNaviTxt">
                <div className="vAlign"></div>
                <span id="timeProgressTxt" className="timeProgressTxt vMiddle">
                  0:00/0:00
                </span>
              </div>
              <div id="audioMin" className="audioMin pointer"></div>
              <div
                id="audioVolumeController"
                className="audioVolumeController vMiddle"
              >
                <div className="vAlign"></div>
                <div id="audioProgressBar" className="audioProgressBar">
                  <div id="audioVolume" className="audioVolume"></div>
                  <div
                    id="audioVolumeSlider"
                    className="audioVolumeSlider pointer"
                  ></div>
                </div>
              </div>
              <div id="audioMax" className="audioMax pointer"></div>
              <div className="vAlign"></div>
              <div className="controlItemsRight">
                <div id="speedIcon2" className="speedIcon pointer">
                  <div className="vAlign"></div>
                  <div className="speed">100%</div>
                  <div className="vAlign"></div>
                </div>
                <div
                  id="fullScreenIcon"
                  className="fullScreenIcon pointer"
                ></div>
              </div>
            </div>
          </div>

          <div id="audioRecordContainer" className="audioRecordContainer"></div>
        </div>
        <div
          className="loadingBar"
          style={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(34, 188, 180, 0.20)",
            textAlign: "center",
          }}
        >
          <div
            style={{
              display: "inline-block",
              width: "0px",
              height: "100%",
              position: "relative",
              verticalAlign: "middle",
            }}
          ></div>
          <span style={{ verticalAlign: "middle" }}>Player Initializing</span>
          <span id="loadInfo" style={{ verticalAlign: "middle" }}>
            0%
          </span>
        </div>
      </div>
    </div>
  );
});
