import React, { Component } from "react";
import Header from "./header";
import Footer from "./footer";
import Publish from "../modal/publish";
import axios from "axios";
import { Redirect } from "react-router";
import ViewConfig from "./viewconfig";
import { homepage, header } from "../config";
import Loader from "../modal/loader";
import ShowMessage from "../modal/showmessage";
import CourseModule from "./viewcoursetopic";

class Coursedtails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      courseid: "",
      coursename: "",
      courseimg1: "",
      redirect: false,
      isPublishSuccess: false,
      errorMessage: "",
      showMessage: "",
      isMessagePopUp: false,
      isMessagePopUpError: false,
      isApiPopUp: false,
      coursebg1: "",
      isConfigActive: 0,
    };
  }

  componentWillMount() {
    var token = localStorage.getItem("accessToken");
    if (token) {
      if (this.props.location.state) {
        const courseid = this.props.location.state.courseid;
        this.setState({ courseid });
      } else {
        this.props.history.push("/");
      }
    } else {
      this.setState({ redirect: true });
    }
  }

  handlePublish = () => {
    this.setState({ isPublishSuccess: true });
  };

  closePublishModal = () => {
    this.setState({ isPublishSuccess: false });
  };

  publishCourse = () => {
    var today = Math.round(new Date().getTime() / 1000);
    var token = localStorage.getItem("accessToken");
    if (token) {
      this.setState({ isApiPopUp: true, isPublishSuccess: false });
      axios
        .get(`${homepage.publishCourse}/${this.state.courseid}?c=${today}`, {
          headers: header,
        })
        .then((response) => {
          this.setState({
            isMessagePopUp: true,
            showMessage: response.data.message,
            isPublishSuccess: false,
            isApiPopUp: false,
          });
          setTimeout(
            () => this.setState({ showMessage: "", isMessagePopUp: false }),
            2000
          );
        })
        .catch((error) => {
          console.log("Error", error);
          this.setState({
            isMessagePopUpError: true,
            //showMessage: error.response.data.message,
            showMessage: "Something went wrong",
            isApiPopUp: false,
            isPublishSuccess: false,
          });
          setTimeout(
            () =>
              this.setState({ showMessage: "", isMessagePopUpError: false }),
            2000
          );
        });
    }
  };

  getCourse = (course_id) => {
    var token = localStorage.getItem("accessToken");
    if (token) {
      token = token.substring(1, token.length - 1);
      var today = Math.round(new Date().getTime() / 1000);
      axios
        .get(`${homepage.getCourseById}/${course_id}?c=${today}`, {
          headers: header,
        })
        .then((res) => {
          if (res) {
            this.setState({
              coursename: res.data.data.name,
              courseimg1: res.data.data.image,
              coursebg1: res.data.data.bg,
            });
          }
        })
        .catch((error) => {
          if (error.response !== undefined && error.response.status === 401) {
            this.setState({ redirect: true });
          }
        });
    } else {
      this.setState({ redirect: true });
    }
  };

  componentDidMount() {
    var course_id =
      this.props.location.state && this.props.location.state.courseid;
    var isConfigActiveValue = localStorage.getItem("isConfigActive");

    if (isConfigActiveValue != null || isConfigActiveValue != undefined) {
      this.setState({ isConfigActive: isConfigActiveValue });
    }
    if (course_id) {
      this.setState({
        courseid: course_id,
      });
      this.getCourse(course_id);
    } else {
      this.setState({ redirect: true });
    }
  }

  handleConfigActive = () => {
    this.setState({ isConfigActive: 0 }, () =>
      localStorage.setItem("isConfigActive", 0)
    );
  };

  handleModuleActive = () => {
    this.setState({ isConfigActive: 1 }, () =>
      localStorage.setItem("isConfigActive", 1)
    );
  };

  render() {
    const {
      coursename,
      courseimg1,
      redirect,
      isPublishSuccess,
      errorMessage,
      showMessage,
      isMessagePopUp,
      isMessagePopUpError,
      isApiPopUp,

      isConfigActive,
    } = this.state;

    return redirect ? (
      <Redirect to={"/login"} />
    ) : (
      <div>
        <Header />
        <section className="main-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-5 col-sm-5">
                <div className="left-paanel">
                  <div className="courseImage">
                    <img src={courseimg1} className="img-fluid" alt="" />
                  </div>
                  <div className="courseContent">
                    <h2>{coursename}</h2>
                  </div>
                  <div className="courseType">
                    <ul className="nav nav-tabs">
                      <li>
                        <button
                          className={isConfigActive == 0 ? "active" : ""}
                          data-toggle="tab"
                          onClick={this.handleConfigActive}
                        >
                          <span className="tab-icon config-icon"></span>Config
                        </button>
                      </li>
                      <li>
                        <button
                          className={isConfigActive == 1 ? "active" : ""}
                          data-toggle="tab"
                          onClick={this.handleModuleActive}
                        >
                          <span className="tab-icon module-icon"></span>Module
                        </button>
                      </li>
                      <li>
                        <button
                          className={
                            isConfigActive != 0 || isConfigActive != 1
                              ? "publish"
                              : ""
                          }
                          data-toggle="tab"
                          onClick={this.handlePublish}
                        >
                          <span className="tab-icon module-icon"></span>Publish
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 col-md-7 col-sm-7">
                {isConfigActive == 0 && <ViewConfig />}
                {isConfigActive == 1 && <CourseModule />}
              </div>
            </div>
          </div>
        </section>
        <Publish
          isPublishSuccess={isPublishSuccess}
          handlePublishSure={this.publishCourse}
          publishModalClose={this.closePublishModal}
        />
        <Loader isApiPopUp={isApiPopUp} />
        <ShowMessage
          showMessage={showMessage}
          errorMessage={errorMessage}
          isMessagePopUp={isMessagePopUp}
          isMessagePopUpError={isMessagePopUpError}
        />
        <Footer />
      </div>
    );
  }
}
export default Coursedtails;
