import React from "react";

const Loader = (props) => {
  return (
    <>
      {props.isApiPopUp && (
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body loaderBox" style={{ margin: "auto" }}>
              <div className="loader-popup">
                <div className="loader">
                  <i className="fas fa-spinner"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
