import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer-content">
            <ul>
              <li>
                <a
                  href={this.props.terms_condition_img}
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.props.term_condition}
                </a>
              </li>
              <li>
                <a
                  href={this.props.license_agreement_img}
                  target="_blank"
                  rel="noreferrer"
                >
                  {this.props.licence_agreement}
                </a>
              </li>
            </ul>
            <p>
              {this.props.copy_right}{" "}
              {this.props.acknowledgement_link ? "|" : " "}
              <a
                href={this.props.acknowledgement_link_image}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                {this.props.acknowledgement_link}
              </a>
            </p>
          </div>
        </div>
      </footer>
    );
  }
}
export default Footer;
